<template>
  <div class="field-sign">
    <Transition name="techies-field-sign" mode="out-in">
      <UiImage v-if="isFirstActive" class="field-sign__image" :src="`/static/techies/${images.first}.png`" />
      <UiImage v-else class="field-sign__image" :src="`/static/techies/${images.second}.png`" />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { EFieldSignStates, type IFieldSignProps } from './FieldSign.types';

const props = defineProps<IFieldSignProps>();
const { state } = toRefs(props);

const stateImages = {
  [EFieldSignStates.DONE]: 'green-sign',
  [EFieldSignStates.DEFAULT]: 'red-sign',
  [EFieldSignStates.ACTIVE]: 'hero',
};

const isFirstActive = ref(true);
const images = reactive({
  first: stateImages[state.value],
  second: stateImages[state.value],
});

watch(state, (newState) => {
  const imageKey = isFirstActive.value ? 'second' : 'first';

  images[imageKey] = stateImages[newState];
  isFirstActive.value = !isFirstActive.value;
});
</script>

<style lang="scss">
.techies-field-sign-enter-from,
.techies-field-sign-leave-to {
  transform: scaleY(0);
  transform-origin: bottom;
}

.techies-field-sign-enter-active,
.techies-field-sign-leave-active {
  transition: var(--default-duration) ease-in-out;
}

.techies-field-sign-enter-to,
.techies-field-sign-leave-from {
  transform: scaleY(1);
  transform-origin: bottom;
}
</style>

<style scoped lang="scss" src="./FieldSign.scss" />
