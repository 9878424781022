<template>
  <section class="game-map-controller">
    <div class="game-map-controller__background">
      <UiImage class="game-map-controller__background-map" :src="map" loading="lazy" />
    </div>
    <div class="game-map-controller__content">
      <div class="game-map-controller__content-stairs">
        <div v-if="changeXL(true, false)" class="game-map-controller__content-stairs--start">
          <component :is="stairSvg" class="stair" />
          <component :is="stairSvg" class="stair" />
          <component :is="stairSvg" class="stair" />
        </div>
        <div class="game-map-controller__content-stairs--end">
          <component :is="stairSvg" class="stair" />
          <component :is="stairSvg" class="stair" />
          <component :is="stairSvg" class="stair" />
        </div>
      </div>
      <div class="game-map-controller__content-player">
        <TechiesPlayerIcon />
      </div>
      <div v-for="column in startStage - 1" :key="column" class="game-map-controller__content-column">
        <div class="game-map-controller__content-column__rows">
          <TechiesGameField
            v-for="row in ROWS"
            :key="row"
            :type="EGameFieldTypes.ONE"
            :is-shuffling="isShuffling"
            :state="fieldState(column, row)"
            @interact="handleInteraction(column, row)"
          />
        </div>
      </div>
      <div class="game-map-controller__content-column">
        <div class="game-map-controller__content-column__start">
          <div class="brick"></div>
          <div class="brick"></div>
          <div class="brick"></div>
          <TechiesGameField :type="EGameFieldTypes.LINE" :state="startState" />
        </div>
      </div>
      <div v-for="column in STAGES - startStage" :key="column" class="game-map-controller__content-column">
        <div class="game-map-controller__content-column__rows">
          <TechiesGameField
            v-for="row in ROWS"
            :key="row"
            :type="EGameFieldTypes.ONE"
            :is-shuffling="isShuffling"
            :state="fieldState(column + startStage, row)"
            @interact="handleInteraction(column + startStage, row)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { TPossibleNull } from '~/types/Shared.types';
import type { IInfoGamePlayingCoords } from '~/features/techies/types/info/client.types';

import { EGameFieldStates, EGameFieldTypes } from '~/features/techies/components/GameField/GameField.types';
import { STAGES, ROWS } from '~/features/techies/constants/rules.constants';
import { getBombsCoords } from '~/features/techies/utils/bomb.utils';

import { useInfoStore } from '~/project-layers/cs/features/techies/store/info.store';
import { usePlayerStore } from '~/features/techies/store/player.store';
import { useMapStore } from '~/features/techies/store/map.store';

const { play } = useProjectSounds(SOUND_KEYS.TECHIES.CLICK);

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const infoStore = useInfoStore();
const { startStage, isGameStarted, isStepLoading, isShuffling, shuffleColumn } = storeToRefs(infoStore);

const playerStore = usePlayerStore();
const { bombs, coords, history } = storeToRefs(playerStore);

const mapStore = useMapStore();
const { highlightedColumn } = storeToRefs(mapStore);

const clickedCoord = ref<TPossibleNull<IInfoGamePlayingCoords>>(null);

const map = computed(() => {
  const value = changeXL('map', 'map-m');
  return `/static/techies/${value}.png`;
});

const availableCoords = computed(() => ({
  startX: changeXL(55, 10),
  startY: changeXL(12, 10),
  step: changeXL(112, 84),
}));

const playerStyleCoord = computed(() => {
  const x = availableCoords.value.startX + coords.value.x * availableCoords.value.step;
  const y = availableCoords.value.startY + coords.value.y * availableCoords.value.step;

  return {
    left: x + 'px',
    top: y + 'px',
  };
});

const stairSvg = computed(() => changeXL('LazySvgoTechiesTripleStairL', 'LazySvgoTechiesTripleStair'));

const startState = computed(() => {
  if (isGameStarted.value) return EGameFieldStates.PASSED;
  if (highlightedColumn.value === 0) return EGameFieldStates.PASSED;
  return EGameFieldStates.DEFAULT;
});

const fieldState = computed(() => (column: number, row: number) => {
  if (!isShuffling.value) return getFieldState(column, row);

  const columnDelta = shuffleColumn.value - column;
  return columnDelta === 0 || columnDelta === 1 ? EGameFieldStates.BOMB : EGameFieldStates.DEFAULT;
});

const getFieldState = (column: number, row: number) => {
  if (!isGameStarted.value) {
    return highlightedColumn.value === column - 1 ? EGameFieldStates.CURRENT : EGameFieldStates.DEFAULT;
  }

  if (clickedCoord.value && clickedCoord.value.x + 1 === column && clickedCoord.value.y + 1 !== row)
    return EGameFieldStates.DEFAULT;

  const playerX = coords.value.x + 1;
  if (playerX + 1 === column) return EGameFieldStates.CURRENT; // Если следующее поле для выбора
  if (column > playerX) return EGameFieldStates.DEFAULT; // Если поля дальше чем следующее

  const playerY = coords.value.y + 1;

  const isNowStep = playerX === column && playerY === row;
  const isFromHistory = history.value.find((item) => item.x + 1 === column && item.y + 1 === row);
  if (isNowStep || isFromHistory) return EGameFieldStates.PASSED; // Если поле уже пройдено

  const isBomb = bombs.value.find((item) => item.x + 1 === column && item.y + 1 === row);
  if (isBomb) return EGameFieldStates.BOMB; // Если поле с бомбой

  return EGameFieldStates.DEFAULT; // Дефолтное поле
};

const handleInteraction = async (column: number, row: number) => {
  if (isStepLoading.value) return;

  const coord = { x: column - 1, y: row - 1 };
  clickedCoord.value = coord;

  play();
  await infoStore.makeStep(coord);

  clickedCoord.value = null;
};

watch(
  history,
  (newHistory) => {
    for (const item of newHistory) {
      if (bombs.value.find((bomb) => bomb.x === item.x)) continue;
      bombs.value.push(...getBombsCoords(item.x, item.y));
    }

    if (!newHistory.length) return;
    bombs.value.push(...getBombsCoords(coords.value.x, coords.value.y));
  },
  { deep: true, immediate: true },
);
</script>

<style scoped lang="scss">
.game-map-controller {
  --player-x: v-bind('playerStyleCoord.left');
  --player-y: v-bind('playerStyleCoord.top');
}
</style>

<style scoped lang="scss" src="/features/techies/controllers/GameMapController/GameMapController.scss" />
