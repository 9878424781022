<template>
  <div :class="['game-field', rootClasses]">
    <Transition :name="transitionName" mode="out-in">
      <div v-if="isActivated" class="game-field__activated">
        <LazySvgoTechiesPing class="game-field__activated-ping" />
      </div>
      <div v-else-if="state === EGameFieldStates.BOMB" class="game-field__bomb">
        <UiImage src="/static/techies/bomb.png" />
      </div>
      <div v-else-if="state === EGameFieldStates.CURRENT" class="game-field__current" @click="handleClick">
        <LazySvgoTechiesCurrentSquare class="game-field__current-square" />
        <LazySvgoTechiesStep class="game-field__current-step" />
      </div>
      <div v-else-if="state === EGameFieldStates.PASSED" class="game-field__passed">
        <LazySvgoTechiesStep />
      </div>
      <div v-else class="game-field__default"></div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { EGameFieldStates, EGameFieldTypes, type IGameFieldProps, type IGameFieldEmits } from './GameField.types';

const props = defineProps<IGameFieldProps>();
const emits = defineEmits<IGameFieldEmits>();

const isActivated = ref(false);

const rootClasses = computed(() => ({
  'game-field--full': props.type === EGameFieldTypes.LINE,
}));

const transitionName = computed(() => {
  return props.type === EGameFieldTypes.ONE && props.isShuffling ? 'techies-game-field' : '';
});

const handleClick = () => {
  isActivated.value = true;
  emits('interact');
};

watch(
  () => props.state,
  () => {
    isActivated.value = false;
  },
);
</script>

<style scoped lang="scss">
.techies-game-field-enter-from,
.techies-game-field-leave-to {
  transform: scale(0);
}

.techies-game-field-enter-active,
.techies-game-field-leave-active {
  transition: transform 0.3s;
}

.techies-game-field-enter-to,
.techies-game-field-leave-from {
  transform: scale(1);
}
</style>

<style scoped lang="scss" src="./GameField.scss" />
