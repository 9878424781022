<template>
  <div class="button-block">
    <Transition name="fade" mode="out-in">
      <div v-if="state === EButtonBlockStates.UNAUTH" class="button-block__unauth">
        <GeneralAuthButtonController :amplitude-source-of-click="EAuthButtonPlaceClicked.TECHIES" />
      </div>
      <SharedKitButton
        v-else-if="state === EButtonBlockStates.INGAME"
        class="button-block__disabled"
        :color-preset="TechiesButtonPresets.DISABLED"
        :type-preset="TypePresets.RECTANGLE"
        :size-preset="buttonSize"
        :text="ingameText"
      />
      <div v-else class="button-block__default">
        <SharedKitButton
          v-for="item in buttonData"
          :key="item.variant"
          class="button-block__default-item"
          :color-preset="TechiesButtonPresets.DEFAULT"
          :type-preset="TypePresets.NONE"
          :size-preset="buttonSize"
          :is-loading="loading"
          :text="$t('techies.buttonBlock.startWith')"
          @click="$emit('start', item.price)"
          @pointerover="$emit('hover', item.column)"
          @pointerleave="$emit('leave')"
        >
          <template #append>
            <BaseCurrency class="button-block__default-group-item--append-text">
              {{ getValueRoundedByCurrency(item.price) }}
            </BaseCurrency>
          </template>
        </SharedKitButton>
        <SharedKitButton
          class="button-block__default-item"
          :color-preset="TechiesButtonPresets.DEFAULT"
          :type-preset="TypePresets.NONE"
          :size-preset="buttonSize"
          :is-loading="shuffleLoading"
          :text="$t('techies.buttonBlock.shuffleMines')"
          @click="$emit('shuffle')"
        />
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { EButtonBlockStates, type IButtonBlockProps, type IButtonBlockEmits } from './ButtonBlock.types';

import { TypePresets, SizePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { TechiesButtonPresets } from '~/features/techies/presets/SharedKitButton.presets';
import { EAuthButtonPlaceClicked } from '~/repository/amplitude/types/auth.types';

const { t } = useI18n();

const buttonData = [
  { column: 0, price: 20, variant: 1 },
  { column: 2, price: 40, variant: 2 },
  { column: 3, price: 60, variant: 3 },
];

defineProps<IButtonBlockProps>();
defineEmits<IButtonBlockEmits>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const buttonSize = computed(() => changeXL(SizePresets.L, SizePresets.M));
const ingameText = computed(() =>
  changeXL(t('lostLocales.techies.gameStartedXL'), t('lostLocales.techies.gameStartedSM')),
);
</script>

<style scoped lang="scss" src="./ButtonBlock.scss" />
